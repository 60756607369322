/* Set additional accent color scales here */
.radix-themes [data-accent-color='error'],
.radix-themes [data-accent-color='danger'] {
  --accent-surface: var(--red-surface);
  --accent-contrast: var(--red-contrast);
  --accent-1: var(--red-1);
  --accent-2: var(--red-2);
  --accent-3: var(--red-3);
  --accent-4: var(--red-4);
  --accent-5: var(--red-5);
  --accent-6: var(--red-6);
  --accent-7: var(--red-7);
  --accent-8: var(--red-8);
  --accent-9: var(--red-9);
  --accent-10: var(--red-10);
  --accent-11: var(--red-11);
  --accent-12: var(--red-12);
  --accent-a1: var(--red-a1);
  --accent-a2: var(--red-a2);
  --accent-a3: var(--red-a3);
  --accent-a4: var(--red-a4);
  --accent-a5: var(--red-a5);
  --accent-a6: var(--red-a6);
  --accent-a7: var(--red-a7);
  --accent-a8: var(--red-a8);
  --accent-a9: var(--red-a9);
  --accent-a10: var(--red-a10);
  --accent-a11: var(--red-a11);
  --accent-a12: var(--red-a12);
}

.radix-themes [data-accent-color='success'] {
  --accent-surface: var(--green-surface);
  --accent-contrast: var(--green-contrast);
  --accent-1: var(--green-1);
  --accent-2: var(--green-2);
  --accent-3: var(--green-3);
  --accent-4: var(--green-4);
  --accent-5: var(--green-5);
  --accent-6: var(--green-6);
  --accent-7: var(--green-7);
  --accent-8: var(--green-8);
  --accent-9: var(--green-9);
  --accent-10: var(--green-10);
  --accent-11: var(--green-11);
  --accent-12: var(--green-12);
  --accent-a1: var(--green-a1);
  --accent-a2: var(--green-a2);
  --accent-a3: var(--green-a3);
  --accent-a4: var(--green-a4);
  --accent-a5: var(--green-a5);
  --accent-a6: var(--green-a6);
  --accent-a7: var(--green-a7);
  --accent-a8: var(--green-a8);
  --accent-a9: var(--green-a9);
  --accent-a10: var(--green-a10);
  --accent-a11: var(--green-a11);
  --accent-a12: var(--green-a12);
}

.radix-themes [data-accent-color='warning'] {
  --accent-surface: var(--amber-surface);
  --accent-contrast: var(--amber-contrast);
  --accent-1: var(--amber-1);
  --accent-2: var(--amber-2);
  --accent-3: var(--amber-3);
  --accent-4: var(--amber-4);
  --accent-5: var(--amber-5);
  --accent-6: var(--amber-6);
  --accent-7: var(--amber-7);
  --accent-8: var(--amber-8);
  --accent-9: var(--amber-9);
  --accent-10: var(--amber-10);
  --accent-11: var(--amber-11);
  --accent-12: var(--amber-12);
  --accent-a1: var(--amber-a1);
  --accent-a2: var(--amber-a2);
  --accent-a3: var(--amber-a3);
  --accent-a4: var(--amber-a4);
  --accent-a5: var(--amber-a5);
  --accent-a6: var(--amber-a6);
  --accent-a7: var(--amber-a7);
  --accent-a8: var(--amber-a8);
  --accent-a9: var(--amber-a9);
  --accent-a10: var(--amber-a10);
  --accent-a11: var(--amber-a11);
  --accent-a12: var(--amber-a12);
}

.radix-themes [data-accent-color='info'] {
  --accent-surface: var(--sky-surface);
  --accent-contrast: var(--sky-contrast);
  --accent-1: var(--sky-1);
  --accent-2: var(--sky-2);
  --accent-3: var(--sky-3);
  --accent-4: var(--sky-4);
  --accent-5: var(--sky-5);
  --accent-6: var(--sky-6);
  --accent-7: var(--sky-7);
  --accent-8: var(--sky-8);
  --accent-9: var(--sky-9);
  --accent-10: var(--sky-10);
  --accent-11: var(--sky-11);
  --accent-12: var(--sky-12);
  --accent-a1: var(--sky-a1);
  --accent-a2: var(--sky-a2);
  --accent-a3: var(--sky-a3);
  --accent-a4: var(--sky-a4);
  --accent-a5: var(--sky-a5);
  --accent-a6: var(--sky-a6);
  --accent-a7: var(--sky-a7);
  --accent-a8: var(--sky-a8);
  --accent-a9: var(--sky-a9);
  --accent-a10: var(--sky-a10);
  --accent-a11: var(--sky-a11);
  --accent-a12: var(--sky-a12);
}
