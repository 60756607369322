:root {
  --easing-standard: cubic-bezier(0.2, 0, 0, 1);
  --easing-standard-in: cubic-bezier(0.3, 0, 1, 1);
  --easing-standard-out: cubic-bezier(0, 0, 0, 1);

  --easing-emphasized: cubic-bezier(0.2, 0, 0, 1);
  --easing-emphasized-in: cubic-bezier(0.3, 0, 0.8, 0.15);
  --easing-emphasized-out: cubic-bezier(0.05, 0.7, 0.1, 1);

  --duration-scale: 1;

  /**
   * Short durations
   *
   * These are used for small utility-focused transitions.
   */
  --duration-1: calc(50ms * var(--duration-scale));
  --duration-2: calc(100ms * var(--duration-scale));
  --duration-3: calc(150ms * var(--duration-scale));
  --duration-4: calc(200ms * var(--duration-scale));
  --duration-sh: var(--duration-4);

  /**
   * Medium durations
   *
   * These are used for transitions that traverse a medium area of the
   * screen.
   */
  --duration-5: calc(250ms * var(--duration-scale));
  --duration-6: calc(300ms * var(--duration-scale));
  --duration-7: calc(350ms * var(--duration-scale));
  --duration-8: calc(400ms * var(--duration-scale));
  --duration-md: var(--duration-8);

  /**
   * Long durations
   *
   * These durations are often paired with Emphasized easing. They're used
   * for large expressive transitions.
   */
  --duration-9: calc(450ms * var(--duration-scale));
  --duration-10: calc(500ms * var(--duration-scale));
  --duration-11: calc(550ms * var(--duration-scale));
  --duration-12: calc(600ms * var(--duration-scale));
  --duration-lg: var(--duration-12);

  /**
   * Extra long durations
   *
   * Though rare, some transitions use durations above 600ms. These are
   * usually used for ambient transitions that don't involve user input.
   */
  --duration-13: calc(700ms * var(--duration-scale));
  --duration-14: calc(800ms * var(--duration-scale));
  --duration-15: calc(900ms * var(--duration-scale));
  --duration-16: calc(1000ms * var(--duration-scale));
  --duration-xl: var(--duration-16);
}

* {
  transition-timing-function: var(--easing-standard);
}

@media (prefers-reduced-motion) {
  :root {
    --duration-scale: 0;
  }
}
