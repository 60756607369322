* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
button {
  all: unset;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
