/*@value debug: 1px dashed rgba(82, 77, 255, 0.5) !important;*/

.page {
  --row-height-header: 68px;

  --column-width-1: 1fr;
  --column-width-2: 1fr;
  --column-width-3: 1fr;

  --page-layout-row-gap: var(--space-5);
  --page-layout-column-gap: var(--space-5);
  --page-layout-gap: var(--page-layout-row-gap) var(--page-layout-column-gap);

  --container-1: 448px;
  --container-2: 688px;
  --container-3: 880px;
  --container-4: 1340px;

  --container-margin-inline: var(--space-4);
  --container-margin-block: var(--space-2);

  flex-grow: 1;
}

.page :global(.rt-r-gap-page-layout) {
  gap: var(--page-layout-gap);
}

.page-grid {
  display: grid;
  grid-template-columns:
    [nav] var(--column-width-1) [main] var(--column-width-2) [aside] var(--column-width-3) [aside-end];
  grid-template-rows: var(--row-height-header);
  grid-auto-flow: row dense;
  gap: var(--page-layout-gap);
}

.pageBody {
  composes: page-grid;
}

.logo {
  outline: debug;
  grid-row: 1;
  grid-column: 2;
  object-position: center left;
  object-fit: scale-down;
  place-self: center center;
}

.header {
  outline: debug;
  display: flex;
  flex-direction: column;
  grid-row: 2;
  grid-column: 1 / -1;
  align-self: center;
  position: relative;
}

.crumbs {
  position: absolute;
  transform: translateY(-100%);
  font-size: var(--font-size-1);
}

.nav-variant-secondary {
  outline: debug;
  grid-column: nav / aside;
  width: 100%;
}

.nav-variant-primary-normal {
  outline: debug;
  display: none;
  min-width: 220px;
  grid-column: nav;
  grid-row: span 3;
}

.nav-variant-primary-sheet {
  grid-column: 1 / -1;
}

.navPrimarySheetContent {
  composes: page-grid;
  --color-panel-solid: var(--color-background);
  composes: page;
}

.navPrimarySheetTrigger:global(.rt-Button),
.navPrimarySheetClose:global(.rt-Button) {
  outline: debug;
  place-self: center start;
  grid-row: 1;
  grid-column: 1;
}

.navPrimarySheetTrigger[data-state='open'] {
  opacity: 0;
}

.content {
  grid-column: nav / aside;
  display: flex;
  flex-direction: column;
  gap: var(--page-layout-gap);
}

.contentColumnMain,
.contentColumnAside {
  height: fit-content;
  outline: debug;
  display: contents;
  min-width: 220px;
}

.contentBlock {
  order: var(--content-order, initial);
  width: 100%;
}

.contentBlock:where(:global(.rt-r-size-1)) {
  grid-column: span 1;
}

.contentBlock:where(:global(.rt-r-size-2)) {
  grid-column: span 2;
}

.contentBlock:where(:global(.rt-r-size-1)):nth-child(odd) {
  justify-self: start;
}

.contentBlock:where(:global(.rt-r-size-1)):nth-child(even) {
  justify-self: end;
}

.headerActionTrigger {
  outline: debug;
  grid-row: 1;
}

.headerMetaTitle {
  display: inline;
  font-weight: var(--font-weight-regular);
  color: var(--color-secondary);
  margin-left: var(--space-2);
}

.profileName {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileTrigger:global(.rt-Button) {
  outline: debug;
  place-self: center end;
  grid-row: 1;
  grid-column: aside;
  width: 100%;
  max-width: fit-content;
  gap: var(--space-2);
}

.profileItem {
  gap: var(--space-2);
}

.profileContent {
  min-width: var(--radix-dropdown-menu-trigger-width);
}

/**
 *  xs
 */
@media (min-width: 520px) {
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
  }

  .content> :not(.contentColumnMain, .contentColumnAside) {
    /* Pages that don't opt in to this layout should span everything */
    grid-column: 1 / span 3;
  }
}

/**
 *  sm
 */
@media (min-width: 768px) {
  .page {
    --column-width-1: auto;
    --column-width-3: minmax(220px, min(24%, 320px));
  }

  .logo {
    grid-column: 1 / span 3;
  }

  .content {
    grid-column: nav / aside;
    display: grid;
    grid-template-columns: subgrid;
    grid-auto-flow: row dense;
  }

  .contentColumnMain,
  .contentColumnAside {
    display: grid;
    gap: var(--page-layout-gap);
  }

  .contentColumnMain {
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / span 2;
  }

  .content> :not(.contentColumnMain, .contentColumnAside) {
    grid-column: 1 / span 3;
  }

  .contentColumnAside {
    grid-template-columns: 1fr;
    grid-column: aside;
    height: fit-content;
  }

  .contentColumnAside .contentBlock {
    grid-column: 1;
  }

  .contentBlock:where(:global(.rt-r-size-1)):nth-child(odd),
  .contentBlock:where(:global(.rt-r-size-1)):nth-child(even) {
    justify-self: initial;
  }
}

/**
 * md
 */
@media (min-width: 1024px) {
  .page {
    --column-width-1: auto;
  }

  .logo {
    grid-column: nav;
    place-self: center start;
  }

  .header {
    grid-row: 1;
    grid-column: main;
  }

  .nav-variant-primary-normal {
    display: block;
  }

  .navPrimarySheetTrigger:global(.rt-BaseButton) {
    display: none;
  }

  .nav-variant-secondary {
    grid-column: main / aside;
  }

  .content {
    grid-column: main / aside;
    display: grid;
    grid-template-columns: subgrid;
    grid-auto-flow: row dense;
  }

  .contentColumnMain {
    grid-column: main;
  }
}

/**
 * lg
 */
@media (min-width: 1240px) {
  .page {
    --container-margin-block: var(--space-3);
  }
}

/**
 * xl
 */
@media (min-width: 1380px) {}
