.overlay {
  position: fixed;
  inset: 0;
}

.overlay::before {
  content: '';
  position: fixed;
  inset: 0;
  background-color: var(--color-overlay);
}

.overlay[data-state='closed'] {
  animation: no-op var(--duration-sh);
}

.overlay[data-state='open']::before {
  animation: fade-in var(--duration-sh) var(--easing-standard);
}

.overlay[data-state='closed']::before {
  animation: fade-out var(--duration-sh) var(--easing-standard);
}

.content[data-state='open'] {
  animation: slide-in var(--duration-sh) var(--easing-standard);
}

.content[data-state='closed'] {
  animation: slide-out var(--duration-sh) var(--easing-standard);
}

.content {
  position: fixed;
  background: var(--color-panel-solid);
  box-shadow: var(--shadow-6);
  padding-inline: var(--container-margin-inline);
  padding-block: var(--container-margin-block);
}

.content-side-top,
.content-side-bottom {
  inset-inline: 0;
  min-height: fit-content;
  /*  min-width: 100%;*/
}

.content-side-top {
  --transform-out: translateY(-100%);
  --transform-in: translateY(0);
  top: 0;
}

.content-side-bottom {
  --transform-out: translateY(100%);
  --transform-in: translateY(0);
  bottom: 0;
}

.content-side-left,
.content-side-right {
  inset-block: 0;
  min-width: fit-content;
  min-height: 100%;
}

.content-side-left {
  --transform-out: translateX(-100%);
  --transform-in: translateX(0);
  left: 0;
}

.content-side-right {
  --transform-out: translateX(100%);
  --transform-in: translateX(0);
  right: 0;
}

.content-side-top {
}

.header {
}

.footer {
}

.title {
}

.description {
}

@keyframes no-op {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: var(--transform-out);
  }
  to {
    transform: var(--transform-in);
  }
}

@keyframes slide-out {
  from {
    transform: var(--transform-in);
  }
  to {
    transform: var(--transform-out);
  }
}
