@import '@radix-ui/themes/styles.css';
@import './reset.css';
@import './colors.css';
@import './shadows.css';
@import './animation.css';

:root {
  /* Set global page variables here */
}

.radix-themes {
  /* Set global theme variables here */
  --color-background: var(--gray-2);
  --default-font-size: var(--font-size-2);
  --color-primary: var(--gray-12);
  --color-secondary: var(--gray-a11);
  --default-color: var(--color-primary);

  color: var(--default-color);
}

.radix-themes[data-is-root-theme='true'] {
  /* Only set this on the root theme, otherwise this affects dialogs,
     selects, popovers etc. */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

:is(.dark, .dark-theme),
:is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  /* Set global theme variables for dark mode here */
  --color-background: var(--gray-1);
}
