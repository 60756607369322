.root-primary {
}

.root-secondary {
}

.list {
  display: flex;
  gap: var(--space-5);
}

.group {
  display: flex;
  row-gap: var(--space-1);
  column-gap: var(--space-6);
}

.root[data-orientation='horizontal'] .list,
.root[data-orientation='horizontal'] .group {
  flex-direction: row;
}

.root[data-orientation='horizontal'] .group {
  flex-direction: row;
  gap: var(--space-5);
  border-bottom: 1px var(--gray-6) solid;
  width: fit-content;
}

.root[data-orientation='vertical'] .list,
.root[data-orientation='vertical'] .group {
  flex-direction: column;
}

.item {
  display: flex;
  list-style: none;
}

.item[aria-disabled='true'] {
  pointer-events: none;
  opacity: 0.5;
}

.link-primary {
  composes: rt-reset rt-BaseButton rt-Button rt-r-size-3 rt-variant-soft rt-high-contrast from global;
  margin-inline: calc(-1 * var(--space-3));

  font-size: var(--font-size-2) !important;
}

.link-primary[data-active] {
  background-color: var(--accent-3);
  color: var(--accent-12);
}

.link-primary:not([data-active]):not(:hover, :active) {
  background: transparent;
  color: var(--color-secondary);
}

.link-secondary {
  padding: var(--space-2) var(--space-4);
  border-bottom: 2px transparent solid;

  font-weight: var(--font-weight-medium);
}

.link-secondary:not([data-active]):hover {
  background-color: var(--accent-a4);
  border-top-left-radius: var(--radius-2);
  border-top-right-radius: var(--radius-2);
}

.link-secondary[data-active] {
  color: var(--color-primary);
  border-bottom: 2px var(--accent-10) solid;
}

.link-secondary:not([data-active]):not(:hover, :active) {
  color: var(--color-secondary);
}

.indicator {
  background: pink;
}
